import { createApplication, entityConfigs } from '@shapeable/core';
import { themes } from '@shapeable/theme';
import { ApplicationLogo, ApplicationLogoInverted } from './components';

const theme = themes.OCEAN_THEME;

// pre-patch configs to ensure that derived properties are correctly created 

export const application = createApplication({
  theme,
  entityConfigs,
  options: {
    systemsMapping: 'full',
  },
  components: {
    Logo: ApplicationLogo,
    LogoInverted: ApplicationLogoInverted,
  },
});

/* ---- Patch Entities definitions afterwards to ensure sub-props are already set --- */

const entities = application.entities;